import React from "react"

const error404Page = () => {
  return (
    <div>
      Oh no, its a 404 page. <a href="/">Click here to go back!</a>
    </div>
  )
}

export default error404Page
